import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../../layout'

import BackgroundContainer from '../../../components/image/background-container'
import TitleContainer from '../../../components/common/title-container'
import Title from '../../../components/common/title'
import PageContainer from '../../../components/common/page-container'

import Link from '../../../components/common/link'
import Subtitle from "../../../components/common/subtitle";
import FooterTitle from "../../../components/common/footer-title";

export default (props) => (
    <Layout title="Technical Due Diligence">
        <BackgroundContainer header={props.data.header}>
            <TitleContainer>
                <Title>Due Diligence</Title>
                <Subtitle>A critical friend</Subtitle>
            </TitleContainer>
        </BackgroundContainer>

        <PageContainer>
            <h2>Let us review your product, team, or potential investment</h2>
            <p>Our experience working with a range of different businesses leaves us ideally placed to serve as a critical friend for your business, product, team or potential investment.</p>
            <p>We know what works and what doesn't when it comes to software projects. But we also know that there is often a balance between best practices and business needs. If you need help getting the balance right, or just want to see what you could be improving – we can help.</p>
            <p>We take a straight forward, non-confrontational approach to performing due-dilligence. We understand that business pressures have an impact, that staff changes or outside factors mean your current setup might not be ideal (but is still powering your business, and keeping the lights on!). We look to highlight what can be done to improve things, and to provide some practical steps to achieving them without upsetting your team, or leading to internal upheaval.</p>
            <h2>Due-diligence for your third party suppliers</h2>
            <p>Perhaps you have commissioned another company to conduct some work, be this development, planning, architectural guidance, or something else; we can give you the peace of mind of reviewing this to ensure it is right for your business.</p>
            <h2>Due Diligence for Investors</h2>
            <p>If you are looking to invest in a technology focused business, we can conduct a thorough review of the technical function of that business. Think of it like a home-buyers report, but for a start-up.</p>
            <h2>Due Diligence for founders looking for investment</h2>
            <p>Our team have worked with, worked for, advised and founded businesses which have received investment. But more importantly, we know what the red flags are in a technology business. We can work with you to give you a full breakdown of your businesses technical setup, as well as a plan for what you need to be "investment ready" and other improvements to consider for the long term.</p>
            <h2>An outside pair of eyes</h2>
            <p>By serving as a critical friend, we can give you a different perspective, and highlight concerns which your team may not have even noticed because that is "how it has always been".</p>
        </PageContainer>

        <BackgroundContainer header={props.data.footer}>
            <TitleContainer>
                <FooterTitle>Ready to get some assurance on your project?</FooterTitle>

                <Link to="/contact" className="btn btn-lg mt-3 btn-primary">
                    Get in touch
                </Link>
            </TitleContainer>
        </BackgroundContainer>
    </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "services/due-dil.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footer: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
